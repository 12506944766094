import React from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import Markdown from "highline/components/markdown"
import { detectTabletWidth } from "highline/utils/viewport"
import { toPromoModalFields } from "highline/utils/modal_helper"
import { getPromo } from "highline/redux/helpers/product_detail_helper"
import { contentfulComponentClicked } from "highline/redux/actions/contentful_actions"
import styles from "highline/styles/components/pdp/summary.module.css"
import { shouldExcludeProgram } from "highline/utils/promo_auto_apply_helper"

const Summary = ({
  className,
  isFinalSale,
  isGiftCard,
  isNewColor,
  isNewProduct,
  isTablet,
  isTuxedoBundle,
  layout,
  slug,
  shortDescription,
  name,
  onSale,
  originalPrice,
  price,
  promoCode,
  promoPrice,
  pdpNoveltyBadge,
  isBundle,
}) => {
  const contentfulData = useSelector((state) => state.getIn(["contentful", "globals"]))
  const promo = getPromo(contentfulData)
  const promoTermsAndConditionsModal = toPromoModalFields(promo)
  const dispatch = useDispatch()
  const shouldRenderPromoPreview = !isFinalSale && promoPrice && promoCode && !isGiftCard && !shouldExcludeProgram(slug) && !isBundle
  // const isFinalSaleCheck = isFinalSale || (isFinalSale === undefined && originalPrice !== price)

  return (
    <div className={ classNames(
      "component",
      "summary-component",
      styles.component,
      styles[layout],
      className,
    ) }
    >
      { (pdpNoveltyBadge || isNewProduct || isNewColor) && !isFinalSale && !onSale &&
        <div className = { classNames(
          styles.pdpBadges,
          pdpNoveltyBadge ? styles.noveltyBadge : null,
        ) }>
          { pdpNoveltyBadge
            ? pdpNoveltyBadge
            : isNewProduct
              ? "New"
              : "New Color"
          }
        </div>
      }
      <div className={ styles.productHeading }>

        { layout === "long" &&
          <h1 className={ styles.name }>{ name }</h1>
        }

        { layout === "short" &&
          <div className={ styles.name }>{ name }</div>
        }

        { !onSale &&
          <div className={ styles.price }>
            <span className={ styles.fullPrice }>{ price }</span>
            { isFinalSale &&
              <span className={ styles.finalSale }>Final Sale</span>
            }
          </div>
        }

        { onSale &&
          <div className={ styles.onSaleContainer }>
            <div className={ styles.price }>
              <span className={ styles.strikethroughPrice }>{ originalPrice }</span>
              <span className={ styles.salePrice }>{ price }</span>
            </div>
            { isFinalSale &&
              <span className={ classNames(styles.finalSale, styles.finalSaleOnSale ) }>Final Sale</span>
            }
          </div>
        }

        { shouldRenderPromoPreview &&
          <div className={ styles.promoPriceContainer }>
            <div className={ styles.price }>
              <span className={ styles.salePrice }>
                <span className={ styles.promoPrice }>{ promoPrice }</span>
                <span> with code </span>
                <button
                  className={ styles.promoCodeButton }
                  aria-label="Open Promotion Code Modal"
                  onClick={ () => dispatch(contentfulComponentClicked("modal", promoTermsAndConditionsModal, "Promotion Terms and Conditions Modal")) }
                >{ promoCode }</button>
              </span>
            </div>
          </div>
        }
      </div>
      { shortDescription &&
        <Markdown
          className={ styles.description }
          source={ shortDescription }
        />
      }
    </div>
  )
}

Summary.propTypes = {
  className: PropTypes.string,
  isFinalSale: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isNewColor: PropTypes.bool,
  isNewProduct: PropTypes.bool,
  isTablet: PropTypes.bool,
  isTuxedoBundle: PropTypes.bool,
  layout: PropTypes.oneOf(["long", "short"]),
  name: PropTypes.string,
  onSale: PropTypes.bool,
  originalPrice: PropTypes.string,
  pdpNoveltyBadge: PropTypes.string,
  price: PropTypes.string,
  promoCode: PropTypes.string,
  promoPrice: PropTypes.string,
  shortDescription: PropTypes.string,
  slug: PropTypes.string,
  isBundle: PropTypes.bool,
}

Summary.defaultProps = {
  isGiftCard: false,
  isNewColor: false,
  isNewProduct: false,
  isTablet: detectTabletWidth(),
  isTuxedoBundle: false,
  layout: "long",
  name: "",
  onSale: false,
  pdpNoveltyBadge: "",
  price: "",
  promoCode: "",
  promoPrice: "",
  slug: "",
}

export default Summary
