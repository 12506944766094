import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Markdown from "highline/components/markdown"
import { CloseIcon, GiftIcon, ShirtIcon } from "highline/components/icons"
import { getClientSideLink } from "highline/utils/link"
import Link from "highline/components/secure_link"

import styles from "highline/styles/components/cart/notification.module.css"

const Notification = ({
  className,
  icon,
  link,
  message,
  notificationId,
  onDismissal,
  onMessageClick,
  type,
}) => {
  const columnLink = link ? getClientSideLink(link) : null
  // Avoid errors when no message is provided
  if (!message) return null
  return (

    <div className={ classNames(
      "component",
      "notification-component",
      styles.component,
      styles[type],
      className,
    ) }
    >
      <div className={ styles.messageWrapper }>
        { icon &&
          <div className={ styles.iconWrapper } >
            { icon === "Gift" &&
              <GiftIcon />
            }
            { icon === "Shirt" &&
              <ShirtIcon />
            }
          </div>
        }
        <div className={ styles.message }>
          { link &&
            <Link
              as={ columnLink.get("as") }
              href={ columnLink.get("href") }
            >
              <a className={ styles.notificationLink }
                href={ columnLink.get("as") }
                onClick={ () => onMessageClick(notificationId) }
              >
                <Markdown
                  source={ message }
                />
              </a>
            </Link>
          }
          { !link &&
            <Markdown source={ message } />
          }
        </div>

        <div
          className={ classNames(
            styles.closeButtonWrapper,
            type === "shipping" ? styles.noMessageIconCloseButton : null,
          ) }
        >
          <button
            aria-label="Remove promotional messaging"
            className={ classNames("close-button", styles.closeButton) }
            onClick={ () => onDismissal(notificationId) }
          >
            <div className={ styles.closeIcon }><CloseIcon /></div>
          </button>
        </div>
      </div>
    </div>
  )
}

Notification.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  message: PropTypes.string.required,
  notificationId: PropTypes.string,
  onDismissal: PropTypes.func,
  onMessageClick: PropTypes.func,
  type: PropTypes.oneOf(["bundle", "promo", "shipping"]).isRequired,
}

Notification.defaultProps = {
  icon: null,
  link: null,
}

export default Notification
