import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Input from "highline/components/input"
import LoadingButton from "highline/components/loading_button"

import styles from "highline/styles/components/cart/promo_form.module.css"

const PromoForm = ({
  error,
  onAddPromoSubmit,
  onInputChange,
  code,
  isLoading,
  placeholder,
  className,
}) => (
  <form
    className={ classNames(
      "promo-form-component",
      styles.component,
      className,
    ) }
    method="post"
    onSubmit={ onAddPromoSubmit  }
  >
    <Input
      autoCorrect="off"
      className={ styles.input }
      aria-label="Promo Code"
      error={ error }
      name="code"
      onChange={ onInputChange }
      placeholder={ placeholder }
      spellCheck="false"
      value={ code }
    />
    <div className={ styles.applyButton }>
      <LoadingButton
        aria-label={ "apply promo code" }
        align="stretch"
        layout="secondary"
        loading={ isLoading }
      >
        Apply
      </LoadingButton>
    </div>
  </form>
)

PromoForm.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isPromoCodeApplied: PropTypes.bool,
  onAddPromoSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
}

PromoForm.defaultProps = {
  code: "",
  error: "",
  isLoading: false,
  isPromoCodeApplied: false,
  onAddPromoSubmit: () => {},
  onInputChange: () => {},
  placeholder: "Enter promo code",
}

export default PromoForm
