import React from "react"
import PropTypes from "prop-types"
import Drawer from "highline/components/application/drawer"
import DrawerNavBar from "highline/components/application/drawer_nav_bar"
import classNames from "classnames"
import * as Navigate from "highline/utils/navigate"
import CartContainer from "highline/containers/cart_container"
import AuthContainer from "highline/containers/auth_container"
import ProductPreviewContainer from "highline/containers/product_preview_container"
import { BackArrowIcon } from "highline/components/icons"
import ContentfulContainer from "highline/containers/contentful_container"

const contentTypesAndPresentations = {
  auth: "Sign In",
  cart: "Cart",
  contentful: "Content",
  quickShop: "Quick Shop",
  "": "",
}

const contentTypes = Object.keys(contentTypesAndPresentations)

class RightDrawer extends React.PureComponent {
  static propTypes = {
    cartLayout: PropTypes.oneOf(["cart_standard_width", "cart_double_width"]),
    cmsContent: PropTypes.object,
    contents: PropTypes.oneOf(contentTypes),
    displayTitle: PropTypes.string,
    isCartReady: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onLoad: PropTypes.func,
    onOpen: PropTypes.func,
    onSwapContents: PropTypes.func,
    overlayDuration: PropTypes.number,
    swappableContents: PropTypes.oneOf(contentTypes),
  }

  static defaultProps = {
    cartLayout: "cart_double_width",
    contents: "",
    isCartReady: false,
    isOpen: false,
    onClose: () => {},
    onLoad: () => {},
    onOpen: () => {},
    onSwapContents: () => {},
    overlayDuration: 500,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isCartReady && this.props.isCartReady) {
      this.props.onLoad()
    }
  }

  render() {
    const {
      cmsContent,
      contents,
      displayTitle,
      cartLayout,
      isOpen,
      onClose,
      onOpen,
      onSwapContents,
      overlayDuration,
      swappableContents,
    } = this.props

    let isCart, isAuth, isQuickShop, isContentfulContent, centerContent

    switch (contents){
      case "quickShop":
        centerContent = "Quick Shop"
        isQuickShop = true
        break
      case "cart":
        centerContent = "Your Cart"
        isCart = true
        break
      case "contentful":
        centerContent = displayTitle
        isContentfulContent = true
        break
      case "auth":
        isAuth = true
        break
    }

    return (
      <div
        className={ classNames(
          "container",
          "right-drawer-container",
        ) }
      >
        <Drawer
          cartLayout={ cartLayout }
          isOpen={ isOpen }
          position="right"
          onRequestClose={ onClose }
          overlayDuration={ overlayDuration }
          isCart={ isCart }
        >
          <DrawerNavBar
            centerContent={ centerContent }
            leftCTA={ swappableContents ? <BackArrowIcon /> : null }
            leftCTAPrompt={ swappableContents ? `Open ${contentTypesAndPresentations[swappableContents]}` : null }
            rightCTA={ isOpen ? "Close" : null }
            rightCTAPrompt= { isCart ? "Close the Cart" : null }
            onClickRightCTA={ () => { onClose(contents) } }
            onClickLeftCTA={ swappableContents ? () => { onSwapContents(swappableContents) } : null }
          />
          { isCart &&
            <CartContainer onLoad={ onOpen } />
          }
          { isAuth &&
            <AuthContainer
              redirectOnSuccessUrl={ Navigate.paths.get("checkout") }
              layout="drawer"
            />
          }
          { isQuickShop &&
            <ProductPreviewContainer />
          }
          { isContentfulContent &&
            <ContentfulContainer content = { cmsContent } />
          }
        </Drawer>
      </div>
    )
  }
}

export default RightDrawer
