import ActionTypes from "highline/redux/action_types"
import * as NotificationStorage from "highline/utils/notification_storage"
import { getObjectByFirstField, getField } from "highline/utils/contentful/contentful_helper"
import { getStaticContentByFirstField } from "highline/utils/contentful/static_content_helper"

import {
  filterPreviouslyDismissed,
  getFilteredCartNotifications,
} from "highline/redux/helpers/cart_notifications_helper"

export const loadAsync = () => (
  async (dispatch, getState) => {
    const state = getState()
    const contentfulData = state.getIn(["contentful", "response"])
    const inCartNotifications = getField(getObjectByFirstField(contentfulData, "Cart Messages"), "content")
    if (!inCartNotifications || inCartNotifications.isEmpty()) {
      return dispatch(loadedEmpty())
    }

    const lineItems = state.getIn(["cart", "lineItems"])
    const isPromoAutoApplied = state.getIn(["cart", "isPromoAutoApplied"])

    const bundleDiscount = state.getIn(["cart", "bundleDiscountTotalNumeric"])
    const isPromoApplied = state.getIn(["cart", "promoCodeDetails", "isPromoCodeApplied"])
    const isBundleDiscountApplied = Boolean(bundleDiscount && bundleDiscount > 0)
    const bestDealCartMessage = isPromoApplied && isBundleDiscountApplied && getStaticContentByFirstField(contentfulData, "Best Deal Cart Message")

    const filteredCartNotifications = getFilteredCartNotifications(inCartNotifications, lineItems, isPromoAutoApplied, bestDealCartMessage)

    dispatch(loaded(filteredCartNotifications))
  }
)

export const dismissAsync = (notificationId) => (
  (dispatch, getState) => {
    NotificationStorage.saveDismissal(notificationId)
    const state = getState()
    const currentNotifications = state.getIn(["cartNotifications", "notifications"])
    const notifications = filterPreviouslyDismissed(currentNotifications)
    dispatch(notificationDismissed(notificationId))
    return dispatch(loaded(notifications))
  }
)

const loadedEmpty = () => ({
  type: ActionTypes.CART_NOTIFICATIONS_LOADED_EMPTY,
})

const loaded = (notifications) => ({
  type: ActionTypes.CART_NOTIFICATIONS_LOADED,
  notifications,
})

const notificationDismissed = (notificationId) => ({
  type: ActionTypes.CART_NOTIFICATION_DISMISSED,
  notificationId,
})

export const notificationMessageClicked = (notificationId) => ({
  type: ActionTypes.CART_NOTIFICATION_CLICKED,
  notificationId,
})
