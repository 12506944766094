import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ImmutablePropTypes from "react-immutable-proptypes"
import styles from "highline/styles/components/pdp/error_message.module.css"

const SAME_PRODUCT_BUNDLE_GENERIC_ERROR = " all options for each swatch"

// Oxford-comma list formatting of remaining option types for error messaging
// ex: [color, fit, length] -> "color, fit, & length"
function remainingOptionFormatter(remainingOptions) {
  const options = remainingOptions
    .map((option) => option.get("presentation").toLowerCase())
    .toList()

  switch (options.count()) {
    case 0:
      return ""
    case 1:
      return options.get(0)
    case 2:
      return `${options.get(0)} and ${options.get(1)}`
    default:
      return `${options.slice(0, -1).join(", ")}, & ${options.last()}`
  }
}

const ErrorMessage = ({ showErrors, remainingOptions, isSameProduct, selectedOptions }) => {
  if (!showErrors || !isSameProduct && remainingOptions.isEmpty())
    return null

  const remainingOptionsFiltered = isSameProduct ?
    // for same product bundle, filter out options that have already been selected for current swatch
    remainingOptions.filter((option) => !selectedOptions.has(option.get("name"))) :
    remainingOptions

  const formattedOptionString = isSameProduct && remainingOptionsFiltered.isEmpty() ?
    SAME_PRODUCT_BUNDLE_GENERIC_ERROR :
    remainingOptionFormatter(remainingOptionsFiltered)

  return (
    <div className={ classNames(
      "error-message-component",
      styles.remainingOptions,
    ) }
    >
      Please select { formattedOptionString }
    </div>
  )
}

ErrorMessage.propTypes =  {
  remainingOptions: ImmutablePropTypes.list,
  selectedOptions: ImmutablePropTypes.map,
  showErrors: PropTypes.bool,
  isSameProduct: PropTypes.bool,
}

export default ErrorMessage
