import { connect } from "react-redux"

import {
  addLineItemsToCartAsync,
  addToWishlistAsync,
  cartAddAsGiftAsync,
  cartCloseClicked,
  cartBackClicked,
  cartGiftNoteChanged,
  cartIsGiftInputToggled,
  cartPaypalButtonClicked,
  cartPromoCodeInputChanged,
  cartPromoFieldToggled,
  checkoutAsync,
  deleteLineItemFromCartAsync,
  removeLineItemsFromCartAsync,
  submitAddPromoCodeAsync,
  submitRemovePromoCodeAsync,
  suggestedItemDismissed,
  suggestedItemProductPreviewClicked,
} from "highline/redux/actions/cart_actions"

import {
  loadAsync as loadNotificationsAsync,
  dismissAsync as dismissNotificationAsync,
  notificationMessageClicked,
} from "highline/redux/actions/cart_notification_actions"

import Cart from "highline/components/cart/cart"

const mapStateToProps = (state) => {
  return {
    adyenMerchantSignature: state.getIn(["cart", "adyenMerchantSignature"]),
    bundleDiscountTotal: state.getIn(["cart", "bundleDiscountTotal"]),
    bundleDiscountTotalNumeric: state.getIn(["cart", "bundleDiscountTotalNumeric"]),
    estimatedStoreCredit: state.getIn(["cart", "estimatedStoreCredit"]),
    estimatedStoreCreditNumeric: state.getIn(["cart", "estimatedStoreCreditNumeric"]),
    estimatedTotal: state.getIn(["cart", "estimatedTotal"]),
    giftNote: state.getIn(["cart", "giftNote"]),
    internationalSurcharge: state.getIn(["cart", "internationalSurcharge"]),
    isGift: state.getIn(["cart", "isGift"]),
    isLoading: state.getIn(["cart", "isLoading"]),
    isLoggedIn: state.getIn(["auth", "isLoggedIn"]),
    isNavigatingToCheckout: state.getIn(["cart", "isNavigatingToCheckout"]),
    isPromoFieldOpen: state.getIn(["cart", "isPromoFieldOpen"]),
    isReady: state.getIn(["cart", "isReady"]),
    lineItems: state.getIn(["cart", "lineItems"]),
    markdown: state.getIn(["cart", "markdown"]),
    newLineItemLoading: state.getIn(["cart", "newLineItemLoading"]),
    notifications: state.getIn(["cartNotifications", "notifications"]),
    paypalInputs: state.getIn(["cart", "paypalInputs"]),
    promo: state.getIn(["cart", "promo"]),
    promoCodeDetails: state.getIn(["cart", "promoCodeDetails"]),
    promoError: state.getIn(["cart", "promoError"]),
    shipping: state.getIn(["cart", "shipping"]),
    subtotal: state.getIn(["cart", "subtotal"]),
    suggestedItem: state.getIn(["cart", "suggestedItem"]),
    tax: state.getIn(["cart", "tax"]),
    total: state.getIn(["cart", "total"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddToWishlist: (sku) => {
      dispatch(addToWishlistAsync(sku))
    },

    onCartClose: () => {
      dispatch(cartCloseClicked())
    },

    onCartContentsChanged: () => {
      dispatch(loadNotificationsAsync())
    },

    onCartGiftNoteChanged: (e, giftingEdited) => {
      dispatch(cartGiftNoteChanged(giftingEdited, e.target.value))
    },

    onCartIsGiftInputToggle: (giftingEdited, giftNote, isGift) => {
      const note = isGift ? giftNote : null

      dispatch(cartIsGiftInputToggled(giftingEdited, isGift))
      dispatch(cartGiftNoteChanged(giftingEdited, note))
    },

    onCartShow: () => {
      dispatch(cartBackClicked())
    },

    onCheckout: () => {
      dispatch(checkoutAsync())
    },

    onLineItemDecrement: (lineItems, onComplete) => {
      dispatch(removeLineItemsFromCartAsync(lineItems, onComplete))
    },

    onLineItemDelete: (sku) => {
      dispatch(deleteLineItemFromCartAsync(sku))
    },

    onLineItemIncrement: (lineItems, onComplete) => {
      dispatch(addLineItemsToCartAsync(lineItems, onComplete, true))
    },

    onMessageClick: (notificationId) => {
      dispatch(notificationMessageClicked(notificationId))
    },

    onNotificationDismissal: (notificationId) => {
      dispatch(dismissNotificationAsync(notificationId))
    },

    onPaypalButtonClick: () => {
      dispatch(cartAddAsGiftAsync())
      dispatch(cartPaypalButtonClicked())
    },

    onPromoCodeInputChange: (e) => {
      dispatch(cartPromoCodeInputChanged(e.target.name, e.target.value))
    },

    onPromoCodeAddSubmit: (e) => {
      e.preventDefault()
      dispatch(submitAddPromoCodeAsync())
    },

    onPromoCodeRemoveSubmit: (e) => {
      e.preventDefault()
      dispatch(submitRemovePromoCodeAsync())
    },

    onSuggestedItemClick: (suggestedItem) => {
      dispatch(suggestedItemProductPreviewClicked(suggestedItem))
    },

    onSuggestedItemDismiss: (suggestedItem) => {
      dispatch(suggestedItemDismissed(suggestedItem))
    },

    onTogglePromoField: () => {
      dispatch(cartPromoFieldToggled())
    },
  }
}

const CartContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart)

export default CartContainer
