import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import CartContents from "highline/components/cart/cart_contents"
import { List, Map } from "immutable"
import getConfig from "highline/config/application"

const shouldEnableApplePay = () => {
  const { enableApplePayExpressCheckout } = getConfig()

  if (enableApplePayExpressCheckout &&
    typeof window !== "undefined" &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments()) {
    return true
  }
  return false
}

const shouldEnablePaypalExpress = (isLoggedIn) => {
  const {
    enablePaypalExpressCheckout,
  } = getConfig()

  return !isLoggedIn && enablePaypalExpressCheckout
}

const { enableGifting } = getConfig()

class Cart extends React.PureComponent {
  static propTypes = {
    adyenMerchantSignature: PropTypes.string,
    bundleDiscountTotal: PropTypes.string,
    bundleDiscountTotalNumeric: PropTypes.number,
    cartLayout: PropTypes.oneOf(["cart_standard_width", "cart_double_width"]),
    estimatedStoreCredit: PropTypes.string,
    estimatedStoreCreditNumeric: PropTypes.number,
    estimatedTotal: PropTypes.string,
    giftNote: PropTypes.string,
    internationalSurcharge: PropTypes.string,
    isGift: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isNavigatingToCheckout: PropTypes.bool,
    isPromoFieldOpen: PropTypes.bool,
    isReady: PropTypes.bool,
    lineItems: ImmutablePropTypes.list,
    markdown: PropTypes.string,
    newLineItemLoading: PropTypes.bool,
    notifications: ImmutablePropTypes.list,
    promoCodeDetails: ImmutablePropTypes.mapContains({
      code: PropTypes.string,
      error: ImmutablePropTypes.map,
      isLoading: PropTypes.bool,
      isPromoCodeApplied: PropTypes.bool,
    }),
    onAddToWishlist: PropTypes.func,
    onCartClose: PropTypes.func,
    onCartContentsChanged: PropTypes.func,
    onCartGiftNoteChanged: PropTypes.func,
    onCartIsGiftInputToggle: PropTypes.func,
    onCartShow: PropTypes.func,
    onCheckout: PropTypes.func,
    onLineItemDecrement: PropTypes.func,
    onLineItemDelete: PropTypes.func,
    onLineItemIncrement: PropTypes.func,
    onMessageClick: PropTypes.func,
    onMount: PropTypes.func,
    onNotificationDismissal: PropTypes.func,
    onPaypalButtonClick: PropTypes.func,
    onPromoCodeInputChange: PropTypes.func,
    onPromoCodeAddSubmit: PropTypes.func,
    onPromoCodeRemoveSubmit: PropTypes.func,
    onPromoSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuggestedItemClick: PropTypes.func,
    onSuggestedItemDismiss: PropTypes.func,
    onTogglePromoField: PropTypes.func,
    paypalInputs: ImmutablePropTypes.map,
    promo: ImmutablePropTypes.map,
    promoError: PropTypes.string,
    shipping: ImmutablePropTypes.map,
    storeCredit: PropTypes.string,
    subtotal: PropTypes.string,
    suggestedItem: ImmutablePropTypes.map,
    tax: PropTypes.string,
    total: PropTypes.string,
  }

  static defaultProps = {
    cartLayout: "cart_double_width",
    giftNote: null,
    isGift: false,
    isLoading: false,
    isLoggedIn: false,
    isNavigatingToCheckout: false,
    isPromoFieldOpen: false,
    notifications: List(),
    onMessageClick: () => {},
    onCartContentsChanged: () => {},
    onCartGiftNoteChanged: () => {},
    onCartIsGiftInputToggle: () => {},
    onTogglePromoField: () => {},
    onMount: () => {},
    promoCodeDetails: Map(),
  }

  componentDidMount() {
    this.props.onMount()
  }

  componentWillUpdate(nextProps) {
    if (this.props.lineItems !== nextProps.lineItems) {
      this.props.onCartContentsChanged()
    }
  }

  render() {
    const {
      adyenMerchantSignature,
      bundleDiscountTotal,
      bundleDiscountTotalNumeric,
      estimatedStoreCredit,
      estimatedStoreCreditNumeric,
      estimatedTotal,
      giftNote,
      internationalSurcharge,
      cartLayout,
      isGift,
      isLoading,
      isLoggedIn,
      isNavigatingToCheckout,
      isPromoFieldOpen,
      lineItems,
      markdown,
      newLineItemLoading,
      notifications,
      promoCodeDetails,
      onAddToWishlist,
      onCartClose,
      onCartGiftNoteChanged,
      onCartIsGiftInputToggle,
      onCheckout,
      onMessageClick,
      onSuggestedItemClick,
      onLineItemDecrement,
      onLineItemDelete,
      onLineItemIncrement,
      onNotificationDismissal,
      onPaypalButtonClick,
      onPromoCodeInputChange,
      onPromoCodeAddSubmit,
      onPromoCodeRemoveSubmit,
      onPromoSubmit,
      onSuggestedItemDismiss,
      onTogglePromoField,
      paypalInputs,
      promo,
      promoError,
      shipping,
      subtotal,
      suggestedItem,
      tax,
      total,
    } = this.props

    return (
      <CartContents
        adyenMerchantSignature={ adyenMerchantSignature }
        bundleDiscountTotal = { bundleDiscountTotal }
        bundleDiscountTotalNumeric = { bundleDiscountTotalNumeric }
        cartLayout={ cartLayout }
        closeCart={ onCartClose }
        enableGifting={ enableGifting }
        estimatedStoreCredit={ estimatedStoreCredit }
        estimatedStoreCreditNumeric={ estimatedStoreCreditNumeric }
        estimatedTotal={ estimatedTotal }
        giftNote={ giftNote }
        internationalSurcharge={ internationalSurcharge }
        isGift={ isGift }
        isLoading={ isLoading }
        isNavigatingToCheckout={ isNavigatingToCheckout }
        isPromoFieldOpen={ isPromoFieldOpen }
        lineItems={ lineItems }
        markdown= { markdown }
        newLineItemLoading={ newLineItemLoading }
        notifications={ notifications }
        promoCodeDetails={ promoCodeDetails }
        onCartGiftNoteChanged={ onCartGiftNoteChanged }
        onCartIsGiftInputToggle={ onCartIsGiftInputToggle }
        onSuggestedItemClick={ onSuggestedItemClick }
        onLineItemDecrement={ onLineItemDecrement }
        onLineItemDelete={ onLineItemDelete }
        onLineItemIncrement={ onLineItemIncrement }
        onLineItemWishlist={ onAddToWishlist }
        onMessageClick= { onMessageClick }
        onNotificationDismissal={ onNotificationDismissal }
        onPaypalButtonClick={ onPaypalButtonClick }
        onPromoCodeInputChange={ onPromoCodeInputChange }
        onPromoCodeAddSubmit={ onPromoCodeAddSubmit }
        onPromoCodeRemoveSubmit={ onPromoCodeRemoveSubmit }
        onPromoSubmit={ onPromoSubmit }
        onSubmit={ onCheckout }
        onSuggestedItemDismiss={ onSuggestedItemDismiss }
        onTogglePromoField={ onTogglePromoField }
        paypalInputs={ paypalInputs }
        promo={ promo }
        promoError={ promoError }
        shipping={ shipping }
        showLineItemPrompt={ isLoggedIn }
        showApplePayButton={ shouldEnableApplePay(this.props.isLoggedIn) }
        showPaypalButton={ shouldEnablePaypalExpress(this.props.isLoggedIn) }
        subtotal={ subtotal }
        suggestedItem={ suggestedItem }
        tax={ tax }
        total={ total }
      />
    )
  }
}

export default Cart
