import React from "react"
import LineItem from "highline/components/cart/line_item"
import LineItemPrompt from "highline/components/cart/line_item_prompt"

class LineItemWithPrompt extends React.PureComponent {
  static propTypes = LineItem.propTypes

  static defaultProps = {
    onDecrement: () => {},
    onIncrement: () => {},
  }

  state = {
    selectedPrompt: null,
    showPrompt: false,
  }

  toggleLineItemPrompt = () => {
    this.setState({ showPrompt: !this.state.showPrompt })
  }

  wishlist = (sku, quantity) => {
    this.setState({ selectedPrompt: "wishlist" })
    this.props.onWishlist(sku, quantity)
  }

  deleteItem = (sku, quantity) => {
    this.setState({ selectedPrompt: "delete" })
    this.props.onDelete(sku, quantity)
  }

  render() {
    const {
      lineItemId,
      quantity,
      sku,
      ...lineItemProps
    } = this.props

    const {
      selectedPrompt,
      showPrompt,
    } = this.state


    return (
      <LineItem
        { ...lineItemProps }
        lineItemId={ lineItemId }
        quantity={ quantity }
        sku={ sku }
        className="line-item-with-prompt-component"
        onRemove={ () => this.toggleLineItemPrompt()  }
      >
        { showPrompt &&
          <LineItemPrompt
            layout={ showPrompt ? null : "left100" }
            lineItemId={ lineItemId }
            onCancel={ this.toggleLineItemPrompt }
            onDelete={ () => this.deleteItem(sku, quantity) }
            onWishlist={ () => this.wishlist(sku, quantity) }
            selected={ selectedPrompt }
            quantity={ quantity }
            sku={ sku }
          />
        }
      </LineItem>
    )
  }
}

export default LineItemWithPrompt
