import { 
  readFromLocalStorageOrCookie, 
  storeInLocalStorageWithCookieFallback, 
} from "highline/utils/local_storage"

export const saveDismissal = (notificationId) => {
  const expirationDate = new Date()
  // redisplay dismissed notifications 10 minutes from now
  expirationDate.setTime(expirationDate.getTime() + (10*60000))
  const expiration = { expires: expirationDate.toString() }
  storeInLocalStorageWithCookieFallback(notificationId, expiration)
}

export const loadDismissalForNotification = (notificationId) => {
  return readFromLocalStorageOrCookie(notificationId)
}