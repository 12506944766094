import { Map, List } from "immutable"
import * as NotificationStorage from "highline/utils/notification_storage"
import { getField } from "highline/utils/contentful/contentful_helper"

export const getFilteredCartNotifications = (inCartNotifications, lineItems, isPromoAutoApplied, applyBestDealCartMessage) => {
  const eligibleCartItems = getEligibleCartItems(lineItems)

  const prioritizedNotification = applyBestDealCartMessage || inCartNotifications.find((notification)=> {
    return checkRules(notification, eligibleCartItems, isPromoAutoApplied)
  })

  // If we don't find a notification we should this process and return empty
  if (!prioritizedNotification) return List()

  const mappedNotifications  = mapNotifications(prioritizedNotification)
  const filteredNotifications = filterPreviouslyDismissed(mappedNotifications)
  return filteredNotifications
}

export const getEligibleCartItems = (cartLineItems) => {
  return cartLineItems.reduce((eligibleCartLineItems, lineItem) => {
    //Only full priced items are eligible towards in cart messaging rules
    if (lineItem.get("fullPriceNumeric") === lineItem.get("priceNumeric")){
      eligibleCartLineItems.push(Map({
        productSlug: lineItem.get("slug"),
        quantity: lineItem.get("quantity"),
      }))
    }
    return eligibleCartLineItems
  }, [])
}

export const checkRules = (notification, lineItems, isPromoAutoApplied) => {
  const rules = getField(notification, "cartMessagingRules")
  if (!rules) return false

  if (getField(notification, "identifier") === "Default") return true
  if (getField(notification, "identifier") === "Auto-Applied Promo Message") return isPromoAutoApplied

  const operator = getField(notification, "ruleOperator") || "AND"
  let showMessage = false
  let failedRule = false
  rules.forEach((rule) => {
    switch (operator) {
      case "AND":
        checkCartForRule(lineItems, rule) ? showMessage = true : failedRule = true
        break
      case "OR":
        checkCartForRule(lineItems, rule) ? showMessage = true : failedRule = false
        break
    }
  })
  if (failedRule){
    return false
  }
  if (showMessage) {
    return true
  }
  return false
}

const checkCartForRule = (lineItems, rule) => {
  const productSlug = getField(rule, "productSlug")
  const operation = getField(rule, "operation") || "Equal To"
  const quantity = getField(rule, "quantity") || 1

  let containsRule = false

  lineItems.forEach((lineItem) => {
    if (productSlug === lineItem.get("productSlug")){
      switch (operation) {
        case "Equal To":
          containsRule = lineItem.get("quantity") === quantity
          break
        case "Greater Than or Equal To":
          containsRule = lineItem.get("quantity") >= quantity
          break
      }
    }
  })

  return containsRule
}

export const mapNotifications = (prioritizedNotification) => {
  return List([
    Map({
      icon: getField(prioritizedNotification, "icon"),
      link: getField(prioritizedNotification, "link"),
      message: getField(prioritizedNotification, "message"),
      notificationId: getField(prioritizedNotification, "identifier"),
      type: "bundle",
    }),
  ])
}

export const filterPreviouslyDismissed = (notifications) => {
  const currentDate = new Date()
  return notifications.filter((notification) => {
    const storedValue = NotificationStorage.loadDismissalForNotification(notification.get("notificationId"))
    if (storedValue) {
      return currentDate >= Date.parse(storedValue.expires)
    } else {
      return true
    }
  })
}
