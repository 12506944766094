import React from "react"
import classNames from "classnames"
import { List, Map } from "immutable"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import AlertTag from "highline/components/alert_tag"
import ErrorMessage from "highline/components/pdp/error_message"
import InputWithLabel from "highline/components/input_with_label"
import LoadingButton from "highline/components/loading_button"
import LoadingCurtain from "highline/components/loading_curtain"
import OnlyXLeft from "highline/components/only_x_left"
import Options from "highline/components/pdp/options"
import PdpDrawerImages from "highline/components/pdp/pdp_drawer_images"
import Summary from "highline/components/pdp/summary"
import { generatePDPLink } from "highline/redux/helpers/category_helper"
import { getLoadingButtonText } from "highline/redux/helpers/product_detail_helper"
import styles from "highline/styles/components/pdp/product_preview.module.css"

class ProductPreview extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    collapsedOptions: ImmutablePropTypes.list,
    finalSale: PropTypes.bool,
    handleNotifyMeSubmit: PropTypes.func,
    images: ImmutablePropTypes.list,
    isAddingToCart: PropTypes.bool,
    isLoading: PropTypes.bool,
    isPreorder: PropTypes.bool,
    isReady: PropTypes.bool,
    metaTitle: PropTypes.string,
    onAddToCart: PropTypes.func,
    onLoad: PropTypes.func,
    onOptionChange: PropTypes.func,
    onOptionToggle: PropTypes.func,
    onOutOfStockEmailChange: PropTypes.func,
    onViewDetailsClicked: PropTypes.func,
    options: ImmutablePropTypes.list,
    outOfStockFormErrors: ImmutablePropTypes.map,
    outOfStockSubmitSuccess: PropTypes.bool,
    outOfStockSubscriptionEmailInputValue: PropTypes.string,
    outOfStockSubscriptionErrors: ImmutablePropTypes.map,
    outOfStockSubscriptionIsLoading: PropTypes.bool,
    price: ImmutablePropTypes.map,
    productName: PropTypes.string,
    remainingOptions: ImmutablePropTypes.list,
    requestedOptions: ImmutablePropTypes.map,
    selectedOptions: ImmutablePropTypes.map,
    shortDescription: PropTypes.string,
    showErrors: PropTypes.bool,
    sku: PropTypes.string,
    slug: PropTypes.string.isRequired,
    url: PropTypes.string,
    userEmail: PropTypes.string,
    variant: ImmutablePropTypes.map,
  }

  static defaultProps = {
    collapsedOptions: List(),
    handleNotifyMeSubmit: () => {},
    isAddingToCart: false,
    isPreorder: false,
    isReady: true,
    onAddToCart: () => {},
    onOptionChange: () => {},
    onOptionToggle: () => {},
    onOutOfStockEmailChange: () => {},
    onViewDetailsClicked: () => {},
    options: List(),
    outOfStockFormErrors: Map(),
    outOfStockSubmitSuccess: false,
    outOfStockSubscriptionEmailInputValue: "",
    outOfStockSubscriptionErrors: Map(),
    outOfStockSubscriptionIsLoading: false,
    properties: Map(),
    selectedOptionTypes: Map(),
    shortDescription: "",
    userEmail: "",
    variant: Map(),
    variantDescriptions: List(),
    variantProperties: List(),
  }

  componentDidMount() {
    this.props.onLoad(this.props.slug, this.props.requestedOptions)
  }

  render() {
    const {
      collapsedOptions,
      finalSale,
      handleNotifyMeSubmit,
      images,
      isAddingToCart,
      isLoading,
      isPreorder,
      isReady,
      onAddToCart,
      onOptionChange,
      onOptionToggle,
      onOutOfStockEmailChange,
      onViewDetailsClicked,
      options,
      outOfStockFormErrors,
      outOfStockSubmitSuccess,
      outOfStockSubscriptionEmailInputValue,
      outOfStockSubscriptionErrors,
      outOfStockSubscriptionIsLoading,
      productName,
      price,
      remainingOptions,
      selectedOptions,
      showErrors,
      sku,
      slug,
      userEmail,
      variant,
    } = this.props

    const color = selectedOptions.get("color")
    const filters = selectedOptions
      .map((value) => List([value]))
    const route = generatePDPLink(
      slug,
      color,
      false,
      false,
      filters,
    )
    const variantIsOutOfStock = variant && !variant.get("inStock")
    const hasRemainingOptions = !remainingOptions.isEmpty()
    const loadingButtonText = getLoadingButtonText(isPreorder, selectedOptions, options, finalSale, variantIsOutOfStock, price.get("price"))
    const showNotifyMeEmailInput = variantIsOutOfStock && !userEmail && !hasRemainingOptions && !finalSale
    const outOfStockSubscriptionEmail = userEmail || outOfStockSubscriptionEmailInputValue
    const isNotifyMeDisabled = finalSale || outOfStockSubscriptionIsLoading || !outOfStockSubscriptionEmail
    const isSubmitButtonDisabled = (variantIsOutOfStock && isNotifyMeDisabled) || (!variantIsOutOfStock && isAddingToCart)

    const handleLoadingButtonPress = () => {
      if (!variantIsOutOfStock) {
        onAddToCart()
      } else {
        handleNotifyMeSubmit(outOfStockSubscriptionEmail, options, selectedOptions, slug, productName)
      }
    }

    return (
      <div
        className={ classNames(
          "component",
          "product-preview-component",
          styles.component,
        ) }
        data-loading={ isLoading ? "true" : "false" }
        data-product-sku={ sku }
        data-product-variant-sku={ variant ? variant.get("sku") : "" }
      >
        <div className={ styles.summaryDetailsButtons }>
          <LoadingCurtain show={ isLoading } />

          <div className={ styles.imagesWrapper }>
            <PdpDrawerImages
              images={ images }
              showImages={ isReady }
            />
          </div>

          <div className={ styles.contentWrapper }>
            <div className={ styles.summaryWrapper }>
              <Summary
                className={ styles.summary }
                name={ productName }
                originalPrice={ price.get("fullPrice") }
                onSale={ price.get("onSale") }
                price={ price.get("price") }
                promoCode={ price.get("promoCode") }
                promoPrice={ price.get("promoPrice") }
                slug={ slug }
                layout="short"
              />
            </div>

            <button onClick={ () => { onViewDetailsClicked(route) } }
              className={ styles.viewDetails }>
              View Details
            </button>

            { finalSale &&
              <div className={ styles.finalSale }>
                <span className={ styles.finalSaleText }>
                Final Sale
                </span> - No returns or exchanges
              </div>
            }

            <Options
              collapsedOptions={ collapsedOptions }
              onOptionChange={ onOptionChange }
              onOptionToggle={ onOptionToggle }
              options={ options }
              productName={ productName }
              remainingOptions={ remainingOptions }
              selectedOptions={ selectedOptions }
              showErrors={ showErrors }
              slug={ slug }
              showSwatches
            />

            { variant && variant.get("limitedQuantity") &&
              <OnlyXLeft
                className={ styles.onlyXLeft }
                quantity={ variant.get("limitedQuantity") }
              />
            }

            { variantIsOutOfStock &&
              <AlertTag className={ styles.soldOut }>
                Sold Out
              </AlertTag>
            }

            { showNotifyMeEmailInput &&
              <div>
                <InputWithLabel
                  autoCorrect="off"
                  error={ outOfStockFormErrors.get("email") }
                  label="Email Address"
                  name="email"
                  onChange={ (e) => onOutOfStockEmailChange(e.target.value) }
                  sensitive
                  spellCheck="false"
                  type="email"
                  value={ outOfStockSubscriptionEmailInputValue }
                />
              </div>
            }
          </div>
        </div>

        <div className={ styles.buttonWrapper }>
          <ErrorMessage
            remainingOptions={ remainingOptions }
            showErrors={ showErrors }
          />

          <LoadingButton
            aria-label={ `${ (variantIsOutOfStock && !isPreorder) ? "Get notified when this product is back in stock" : "Add product to your shopping cart" }` }
            name="add_to_cart"
            onClick={ handleLoadingButtonPress }
            disabled={ isSubmitButtonDisabled }
            loading={ isAddingToCart || outOfStockSubscriptionIsLoading }
            layout={ hasRemainingOptions ? "disabled-style" : "primary" }
          >
            { loadingButtonText }
          </LoadingButton>
        </div>

        { outOfStockSubscriptionErrors && outOfStockSubscriptionErrors.has("message") &&
          <p className={ styles.errorMessage }>
            { outOfStockSubscriptionErrors.get("message") }
          </p>
        }

        { outOfStockSubmitSuccess &&
          <p className={ styles.successMessage }>
            { "Successfully subscribed to notifications for this product!" }
          </p>
        }
      </div>
    )
  }
}

export default ProductPreview
