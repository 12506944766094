export const getFormErrors = (options, selectedOptions, email) => {
  const optionsSet = options.map((option) => option.get("name")).toSet()
  const selectedOptionsSet = selectedOptions.keySeq().toSet()

  const errors = optionsSet.subtract(selectedOptionsSet).toMap()

  // User is not logged in and no email is provided
  if (!email) {
    return errors.set("email", "Email Required")
  }

  // User is not logged in and invalid email is provided
  if (!validEmail(email)) {
    return errors.set("email", "Invalid Email")
  }

  // Rest of errors is applicable
  return errors
}

function validEmail(email) {
  return /(.+)@(.+)\.(.+)/.test(email)
}
