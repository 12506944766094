import * as OutOfStockSubscriptionsApi from "highline/api/out_of_stock_subscription_api"
import * as TermsAndPrivacyApi from "highline/api/terms_and_privacy_api"
import ActionTypes from "highline/redux/action_types"
import Rollbar from "highline/utils/rollbar"
import { getFormErrors } from "highline/redux/helpers/out_of_stock_subscription_helper"
import { decamelize } from "humps"

export const outOfStockSubscriptionEmailChanged = (email) => ({
  type: ActionTypes.OUT_OF_STOCK_SUBSCRIPTION_EMAIL_CHANGED,
  email,
})

export const outOfStockSubscriptionFormValidationFailed = (formErrors) => ({
  type: ActionTypes.OUT_OF_STOCK_SUBSCRIPTION_FORM_VALIDATION_FAILED,
  formErrors,
})

export const outOfStockSubscriptionSubmitFailed = (errorMessage) => ({
  message: errorMessage,
  type: ActionTypes.OUT_OF_STOCK_SUBSCRIPTION_SUBMIT_FAILED,
})

export const outOfStockSubscriptionSubmitStarted = () => ({
  type: ActionTypes.OUT_OF_STOCK_SUBSCRIPTION_SUBMIT_STARTED,
})

export const outOfStockSubscriptionSubmitSucceeded = (status, selectedOptions, productName) => ({
  type: ActionTypes.OUT_OF_STOCK_SUBSCRIPTION_SUBMIT_SUCCEEDED,
  selectedOptions,
  status,
  productName,
})

export const outOfStockSubscriptionSubmitAsync = (email, options, selectedOptions, productSlug, productName) => (
  async (dispatch, getState) => {
    dispatch(outOfStockSubscriptionSubmitStarted())

    const state = getState()
    const authToken = state.getIn(["auth", "authenticationToken"])

    const formErrors = getFormErrors(options, selectedOptions, email)
    if (!formErrors.isEmpty()) {
      return dispatch(outOfStockSubscriptionFormValidationFailed(formErrors))
    }

    // Api expects decamelized option type names as the keys
    // Could change the flatiron endpoint to accept variant sku instead of product slug and selectedOptions
    const decamelizedSelectedOptions = selectedOptions.mapKeys((key) => decamelize(key, { separator: "-" }))

    try {
      await TermsAndPrivacyApi.accept(email)
      await OutOfStockSubscriptionsApi.create(authToken, email, productSlug, decamelizedSelectedOptions)

      return dispatch(outOfStockSubscriptionSubmitSucceeded("out of stock", selectedOptions, productName))

    } catch (error) {
      const errorMessage = getErrorMessage(error)
      const isProductInStock = error.status === 422 && errorMessage === "Product is in stock"
      const isProductValid = error.status === 422 && errorMessage === "We currently do not make a product matching the options provided"
      const isPreviouslySubscribedToNotification = errorMessage === "Variant group with this external_id already exists!"

      if (isProductInStock) {
        Rollbar.error("Out of Stock Subscription Error: in-stock product", error) // UI should prevent
        return dispatch(outOfStockSubscriptionSubmitFailed("This product is in stock!"))

      } else if (isProductValid) {
        Rollbar.error("Out of Stock Subscription Error: non-existant product", error) // UI should prevent
        return dispatch(outOfStockSubscriptionSubmitFailed("This product does not exist!"))

      } else if (isPreviouslySubscribedToNotification) {
        return dispatch(outOfStockSubscriptionSubmitFailed("You are already subscribed!"))

      } else {
        Rollbar.error("Out of Stock Subscription: Error occured on API request", error)
        return dispatch(outOfStockSubscriptionSubmitFailed("Something went wrong. Please try again."))
      }
    }
  }
)

const getErrorMessage = (error) => (
  error.data &&
  (error.data.getIn(["errors", "general", 0]) || error.data.getIn(["errors", "outOfStockSubscription", "variantGroup", 0]))
)
