import { connect } from "react-redux"

import {
  RIGHT_DRAWER_OVERLAY_DURATION,
  rightDrawerCloseAsync,
  openRightDrawer,
  swapRightDrawerContents,
} from "highline/redux/actions/right_drawer_actions"

import {
  cartAddAsGiftAsync,
  cartCloseClicked,
  loadCartItemCountAsync,
} from "highline/redux/actions/cart_actions"

import RightDrawer from "highline/components/application/right_drawer"

const mapStateToProps = (state) => {
  return {
    cmsContent: state.getIn(["rightDrawer", "cmsContent", "content"]),
    contents: state.getIn(["rightDrawer", "contents"]),
    displayTitle: state.getIn(["rightDrawer", "cmsContent", "displayTitle"]),
    isCartReady: state.getIn(["cart", "isReady"]),
    isOpen: state.getIn(["rightDrawer", "isOpen"]),
    overlayDuration: RIGHT_DRAWER_OVERLAY_DURATION,
    swappableContents: state.getIn(["rightDrawer", "swappableContents"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(cartAddAsGiftAsync())
      dispatch(rightDrawerCloseAsync())
      dispatch(cartCloseClicked())
    },
    onLoad: () => {
      dispatch(loadCartItemCountAsync())
    },
    onOpen: () => {
      dispatch(openRightDrawer())
    },
    onSwapContents: (contents) => {
      dispatch(swapRightDrawerContents(contents))
    },
  }
}

const RightDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RightDrawer)

export default RightDrawerContainer
