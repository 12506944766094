import React from "react"
import { List } from "immutable"
import PropTypes from "prop-types"
import classNames from "classnames"
import ImmutablePropTypes from "react-immutable-proptypes"
import Notification from "highline/components/cart/notification"

const NotificationList = ({
  className,
  onMessageClick,
  notifications,
  onDismissal,
}) => (
  <div className={ classNames(
    "component",
    "notification-list-component",
    className,
  ) }
  >
    {
      notifications.map((notification) => (
        <Notification
          key={ notification.get("notificationId") }
          icon={ notification.get("icon") }
          link={ notification.get("link") }
          message={ notification.get("message") }
          notificationId={ notification.get("notificationId") }
          onDismissal={ () => onDismissal(notification.get("notificationId")) }
          type={ notification.get("type") }
          onMessageClick={ onMessageClick }
        />
      ))
    }
  </div>
)

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: ImmutablePropTypes.list,
  onDismissal: PropTypes.func,
  onMessageClick: PropTypes.func,
}

NotificationList.defaultProps = {
  notifications: List(),
  onDismissal: () => {},
  onMessageClick: () => {},
}

export default NotificationList
