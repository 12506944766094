import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Input from "highline/components/input"
import Checkbox from "highline/components/checkbox"

import styles from "highline/styles/components/cart/gift.module.css"

const MAX_LENGTH = 250

const Gift = ({
  giftNote,
  isGift,
  onCartGiftNoteChanged,
  onCartIsGiftInputToggle,
}) => {
  const giftNoteValue = giftNote === null ? "Enjoy the gift!" : giftNote

  return (
    <div className={ classNames("gift-component", styles.component) }>
      <Checkbox
        checked={ isGift }
        onChange={ () => onCartIsGiftInputToggle(true, giftNoteValue, !isGift) }
      >
        <span>Let&apos;s make this a gift - add a note!</span>
      </Checkbox>
      { isGift &&
        <Fragment>
          <div className={ styles.giftNoteHelperText }>
            Sending a gift card? The gift message below will be added to your gift card mailer.
          </div>
          <Input
            aria-label="Gift Note"
            autoCorrect="off"
            className={ styles.giftNote }
            maxLength={ MAX_LENGTH }
            name="giftNote"
            onChange={ (e) => onCartGiftNoteChanged(e, true) }
            spellCheck="false"
            value={ giftNoteValue }
          />
          <div className={ styles.giftNoteHelperText }>
            <span>You have { MAX_LENGTH - giftNoteValue.length } of { MAX_LENGTH } characters left!</span>
          </div>
        </Fragment>
      }
    </div>
  )
}

Gift.propTypes = {
  giftNote: PropTypes.string,
  isGift: PropTypes.bool,
  onCartGiftNoteChanged: PropTypes.func,
  onCartIsGiftInputToggle: PropTypes.func,
}

Gift.defaultProps = {
  giftNote: null,
  isGift: false,
  onCartGiftNoteChanged: () => {},
  onCartIsGiftInputToggle: () => {},
}

export default Gift
