import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import LabeledIcon from "highline/components/labeled_icon"
import { CloseIcon, EmptyHeartIcon, TrashIcon } from "highline/components/icons"
import styles from "highline/styles/components/cart/line_item_prompt.module.css"

const LineItemPrompt = ({
  className,
  layout,
  onDelete,
  onCancel,
  onWishlist,
  selected,
  quantity,
  sku,
}) => (
  <div
    className={ classNames(
      "component",
      "line-item-prompt-component",
      styles.component,
      className,
      styles[layout],
    ) }
  >
    <button
      aria-label="Save item"
      className={ classNames(
        "wishlist-button",
        styles.button,
        styles.wishlist,
        selected === "wishlist" ? styles.selected : null,
      ) }
      onClick={ () => onWishlist(sku, quantity) }
    >
      <LabeledIcon className={ styles.icon } svg={ <EmptyHeartIcon /> }>
        Save Item
      </LabeledIcon>
    </button>
    <button
      aria-label="Delete item from your cart"
      className={ classNames(
        "delete-button",
        styles.button,
        styles.delete,
        selected === "delete" ? styles.selected : null,
      ) }
      onClick={ () => onDelete(sku, quantity) }
    >
      <LabeledIcon className={ styles.icon } svg={ <TrashIcon /> }>
        Delete
      </LabeledIcon>
    </button>
    <button
      aria-label="Return to cart"
      className={ classNames(
        "cancel-button",
        styles.button,
        styles.cancel,
        selected === "cancel" ? styles.selected : null,
      ) }
      onClick={ () => onCancel(sku) }
    >
      <LabeledIcon className={ styles.icon } svg={ <CloseIcon /> }>
        Cancel
      </LabeledIcon>
    </button>
  </div>
)

LineItemPrompt.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOf(["left0", "left100"]),
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onWishlist: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  selected: PropTypes.oneOf(["wishlist", "delete", "cancel"]),
  sku: PropTypes.string.isRequired,
}

LineItemPrompt.defaultProps = {
  layout: "left0",
  onCancel: () => {},
  onDelete: () => {},
  onWishlist: () => {},
}

export default LineItemPrompt
