import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import { camelize } from "humps"
import debounce from "debounce"
import ImmutablePropTypes from "react-immutable-proptypes"
import { isClient } from "highline/utils/client"
import { detectSmartphoneWidth } from "highline/utils/viewport"
import ToggleGroup from "highline/components/toggle_group"
import ToggleItem from "highline/components/toggle_item"
import Swatch from "highline/components/swatch"
import TextTag from "highline/components/text_tag"
import classNames from "classnames"
import { optionSizeMapper, getFinalSaleToggleGroups } from "highline/utils/variant_helper"
import styles from "highline/styles/components/pdp/options.module.css"

const RESIZE_DEBOUNCE_TIMEOUT = 200

class Options extends React.PureComponent {
  state = {
    hoveredColor: null,
    isMobile: false,
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    debounce(() => {
      this.setState({ isMobile: detectSmartphoneWidth() })
    }, RESIZE_DEBOUNCE_TIMEOUT)()
  }

  handleSwatchMouseEnter = (name, color) => {
    if (name === "color") {
      this.setState({ hoveredColor: color })
    }
  }

  handleSwatchMouseLeave = () => {
    this.setState({ hoveredColor: null })
  }

  render() {
    const {
      collapsedOptions,
      isFinalSale,
      isBundle,
      isGiftCard,
      isSameProduct,
      onEducationCtaClick,
      onOptionChange,
      onOptionToggle,
      options,
      purchasedOptions,
      selectedOptions,
      showErrors,
      showSwatches,
      sizeAndFitOptionTypes,
      remainingOptions,
    } = this.props

    const { isMobile, hoveredColor } = this.state

    return (
      <div className={ classNames(
        "component",
        "product-options-component",
        styles.component,
      ) }>
        { options.map((optionType) => {
          const title = optionType.get("presentation")
          const name = optionType.get("name")

          if (name === "color" && !showSwatches)
            return /* Don't render swatches */

          let values = optionType.get("values") ? optionType.get("values").toList() : optionType.get("optionValues").toList()
          const totalSwatches = values.size

          const selectedValue = selectedOptions.get(name)

          const purchasedValue = purchasedOptions && purchasedOptions.get(name)

          const selectedValuePresentation = values.find(
            (value) => value.get("name") == selectedValue,
            null,  /* context */
            Map(), /* default */
          ).get("presentation")

          const needsSelection = Boolean(remainingOptions.find((option) =>
            option.get("name") === name,
          ))
          const isSwatch = !isGiftCard && name === "color"
          const showSizeAndFitCta = sizeAndFitOptionTypes.includes(camelize(name))

          // Only collapse option sections for mobile viewports
          const isCollapsed = isClient && isMobile && collapsedOptions.includes(name)
          const titleSupplement = selectedValuePresentation && (
            /* Apply hide/show logic to all sections outside of swatches */
            <div className={ !isSwatch ? styles.titleSupplement : null }>
              <span>{ hoveredColor || selectedValuePresentation }</span>
            </div>
          )

          const showFinalSaleSwatches = isFinalSale && isSwatch
          const saleSwatches = isSwatch && values.filter((value) => value.get("onSale"))
          const allSwatchesOnSale = totalSwatches === saleSwatches.size
          if (saleSwatches.size > 0 && !allSwatchesOnSale) {
            values = values.filter((value) => !value.get("onSale"))
          }

          return (
            <div className={ styles.input } key={ name }>
              <ToggleGroup
                canDeselect={ !isSwatch }
                isFinalSale={ isFinalSale }
                isCollapsed={ isCollapsed }
                isCollapsible={ false }
                isMobile={ isMobile }
                layout={ isSwatch ? "swatch" : "text" }
                name={ name }
                onChange={ onOptionChange }
                /* Do not pass a toggle function for swatches */
                onOptionToggle={ onOptionToggle }
                showError={ showErrors && (isSameProduct ? !selectedValue : needsSelection) }
                title={ isSameProduct && isSwatch ? null : title }
                titleSupplement={ isSameProduct ? null : titleSupplement }
                type="radio"
                value={ selectedValue }
              >
                { !showFinalSaleSwatches &&
                  values.map((value) => (
                    <ToggleItem
                      className={ purchasedValue == value.get("name") ? styles.exchangePurchase : "" }
                      disabled={ value.has("validVariant") ? !value.get("validVariant") : !value.get("purchasable") }
                      key={ value.get("name") }
                      value={ value.get("name") }
                      size={ optionSizeMapper(name) }
                      soldOut={ !value.get("purchasable") }
                      handleSwatchMouseEnter={ this.handleSwatchMouseEnter }
                      handleSwatchMouseLeave={ this.handleSwatchMouseLeave }
                    >
                      { isSwatch
                        ? <Swatch imageUrl={ value.get("url") } />
                        : <TextTag>{ value.get("presentation") }</TextTag>
                      }
                    </ToggleItem>
                  )) }
                { showSizeAndFitCta &&
                  <button
                    className={ classNames(styles.ctaButton, styles.ctaAfter) }
                    aria-label="Open Education Modal"
                    onClick={ () => { onEducationCtaClick(camelize(name)) } }
                  >
                    Find your { optionType.get("shortPresentation").toLowerCase() }
                  </button>
                }
              </ToggleGroup>
              { isSwatch && saleSwatches.size > 0 && !allSwatchesOnSale && !isBundle &&
              <ToggleGroup
                isCollapsible={ false }
                isMobile={ isMobile }
                layout={ "swatch" }
                name={ name }
                onChange={ onOptionChange }
                onOptionToggle={ onOptionToggle }
                titleSupplement={ "On Sale" }
                type="radio"
                value={ selectedValue }
              >
                {
                  saleSwatches.map((value) => (
                    <ToggleItem
                      className={ purchasedValue == value.get("name") ? styles.exchangePurchase : "" }
                      disabled={ !value.get("validVariant") }
                      key={ value.get("name") }
                      value={ value.get("name") }
                      size={ optionSizeMapper(name) }
                      soldOut={ !value.get("purchasable") }
                      handleSwatchMouseEnter={ this.handleSwatchMouseEnter }
                      handleSwatchMouseLeave={ this.handleSwatchMouseLeave }
                    >
                      <Swatch imageUrl={ value.get("url") } />
                    </ToggleItem>
                  )) }
              </ToggleGroup>
              }
              { showFinalSaleSwatches &&
                getFinalSaleToggleGroups(values).map((finalSaleToggleItems, index) => {
                  const finalSaleGroupTitle = finalSaleToggleItems.price && (
                    <span className={ styles.finalSaleOption }>{ finalSaleToggleItems.price }</span>
                  )
                  return (
                    <Fragment key={ `ToggleGroup-${index}` }>
                      <ToggleGroup
                        className= { index != 0 && styles.finalSaleGroupPadding }
                        isFinalSale={ isFinalSale }
                        isCollapsible={ false }
                        isMobile={ isMobile }
                        layout={ "swatch" }
                        name={ name }
                        onChange={ onOptionChange }
                        /* Do not pass a toggle function for swatches */
                        onOptionToggle={ onOptionToggle }
                        showError={ showErrors && (isSameProduct ? !selectedValue : needsSelection) }
                        titleSupplement={ finalSaleGroupTitle }
                        type="radio"
                        value={ selectedValue }
                      >
                        { finalSaleToggleItems.toggleGroup.map((value) => (
                          <ToggleItem
                            className={ purchasedValue == value.get("name") ? styles.exchangePurchase : "" }
                            disabled={ !value.get("validVariant") }
                            key={ value.get("name") }
                            value={ value.get("name") }
                            size={ optionSizeMapper(name) }
                            soldOut={ !value.get("purchasable") }
                            handleSwatchMouseEnter={ this.handleSwatchMouseEnter }
                            handleSwatchMouseLeave={ this.handleSwatchMouseLeave }
                          >
                            <Swatch imageUrl={ value.get("url") } />
                          </ToggleItem>
                        ))}
                      </ToggleGroup>
                    </Fragment>
                  )
                })
              }
            </div>
          )
        }).toList() }
      </div>
    )
  }
}

Options.propTypes = {
  collapsedOptions: ImmutablePropTypes.list,
  isFinalSale: PropTypes.bool,
  isBundle: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isSameProduct: PropTypes.bool,
  onEducationCtaClick: PropTypes.func,
  onOptionChange: PropTypes.func,
  onOptionToggle: PropTypes.func,
  options: ImmutablePropTypes.list.isRequired,
  purchasedOptions: ImmutablePropTypes.map,
  remainingOptions: ImmutablePropTypes.list,
  selectedOptions: ImmutablePropTypes.map,
  showErrors: PropTypes.bool,
  showSwatches: PropTypes.bool,
  sizeAndFitOptionTypes: ImmutablePropTypes.list,
}

Options.defaultProps = {
  collapsedOptions: List(),
  isFinalSale: false,
  isBundle: false,
  isGiftCard: false,
  isSameProduct: false,
  onEducationCtaClick: () => {},
  onOptionChange: () => {},
  onOptionToggle: () => {},
  purcahseOptions: Map(),
  remainingOptions: List(),
  selectedOptions: Map(),
  showErrors: false,
  showSwatches: true,
  sizeAndFitOptionTypes: List(),
}

export default Options
