import { post } from "highline/api/v2_client"

export const create = (authToken, email, productSlug, selectedOptions) => {
  return post("/out_of_stock_subscriptions", {
    accept_privacy_policy: true,
    email,
    product_slug: productSlug,
    selected_options: selectedOptions.toJS(),
  }, {
    "X-Authentication-Token": authToken,
  })
}
