import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useDispatch } from "react-redux"
import { affirmUiReady, convertToCents } from "highline/utils/affirm"
import { affirmEstimateClicked } from "highline/redux/actions/product_detail_actions"
import styles from "highline/styles/components/affirm/affirm_estimate.module.css"

const AffirmEstimate = ({ product, source, totalAmount }) => {
  const dispatch = useDispatch()
  const [affirmPageType, setAffirmPageType] = useState("product")
  const affirmPageTypeMap = {
    "bundle": "product",
    "cart": "cart",
    "pdp": "product",
  }

  useEffect(() => {
    affirmUiReady(() => {
      window.affirm.ui.refresh()
    })
  },[totalAmount])

  useEffect(() => {
    setAffirmPageType(affirmPageTypeMap[source] || "product")
  }, [source])

  return (
    <button
      className={ classNames(
        styles.affirmEstimate,
        "affirm-as-low-as",
      ) }
      data-amount={ convertToCents(totalAmount) }
      data-page-type={ affirmPageType }
      onClick={ () => dispatch(affirmEstimateClicked(source, totalAmount, product)) }
    ></button>
  )
}

AffirmEstimate.propTypes = {
  onAffirmEstimateClick: PropTypes.func,
  product: PropTypes.string,
  source: PropTypes.oneOf(["pdp", "cart", "bundle"]),
  totalAmount: PropTypes.string.isRequired,
}

AffirmEstimate.defaultProps = {
  onAffirmEstimateClick: () => {},
  product: "",
  source: "pdp",
  totalAmount: "0",
}

export default AffirmEstimate
