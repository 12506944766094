import { connect } from "react-redux"
import ProductPreview from "highline/components/pdp/product_preview"
import {
  productPreviewAddToCartAsync,
  productPreviewOptionToggled,
  productPreviewUpdateOptionsAsync,
  productPreviewFetchAsync,
} from "highline/redux/actions/product_preview_actions"
import { productPreviewViewDetailsClickedAsync } from "highline/redux/actions/right_drawer_actions"
import {
  outOfStockSubscriptionEmailChanged,
  outOfStockSubscriptionSubmitAsync,
} from "highline/redux/actions/out_of_stock_subscription_actions"

const mapStateToProps = (state) => {
  return {
    collapsedOptions: state.getIn(["productPreview", "collapsedOptions"]),
    finalSale: state.getIn(["productPreview", "finalSale"]),
    images: state.getIn(["productPreview", "images"]),
    isAddingToCart: state.getIn(["productPreview", "isAddingToCart"]),
    isLoading: state.getIn(["productPreview", "isLoading"]),
    isPreorder: state.getIn(["productDetail", "isPreorder"]),
    isReady: state.getIn(["productPreview", "isReady"]),
    metaTitle: state.getIn(["productPreview", "metaTitle"]),
    options: state.getIn(["productPreview", "options"]),
    outOfStockFormErrors: state.getIn(["outOfStockSubscription", "formErrors"]),
    outOfStockSubmitSuccess: state.getIn(["outOfStockSubscription", "wasSucess"]),
    outOfStockSubscriptionEmailInputValue: state.getIn(["outOfStockSubscription", "email"]),
    outOfStockSubscriptionErrors: state.getIn(["outOfStockSubscription", "errors"]),
    outOfStockSubscriptionIsLoading: state.getIn(["outOfStockSubscription", "isLoading"]),
    price: state.getIn(["productPreview", "price"]),
    productName: state.getIn(["productPreview", "name"]),
    remainingOptions: state.getIn(["productPreview", "remainingOptions"]),
    requestedOptions: state.getIn(["productPreview", "requestedOptions"]),
    selectedOptions: state.getIn(["productPreview", "selectedOptions"]),
    shortDescription: state.getIn(["productPreview", "shortDescription"]),
    showErrors: state.getIn(["productPreview", "showErrors"]),
    sku: state.getIn(["productPreview", "sku"]),
    slug: state.getIn(["productPreview", "slug"]),
    url: state.getIn(["currentPage", "url"]),
    userEmail: state.getIn(["auth", "email"]),
    variant: state.getIn(["productPreview", "variant"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleNotifyMeSubmit: (email, options, selectedOptions, productSlug, productName) => {
      dispatch(outOfStockSubscriptionSubmitAsync(email, options, selectedOptions, productSlug, productName))
    },
    onAddToCart: () => {
      dispatch(productPreviewAddToCartAsync())
    },
    onLoad: (slug, requestedOptions) => {
      const isFromOptionChange = false
      const serverResponse = null

      dispatch(productPreviewFetchAsync(slug, requestedOptions, isFromOptionChange, serverResponse, "quick shop"))
    },
    onOptionChange: (optionName, optionValue) => {
      dispatch(productPreviewUpdateOptionsAsync(optionName, optionValue, false))
    },
    onOptionToggle: (optionName) => {
      dispatch(productPreviewOptionToggled(optionName))
    },
    onOutOfStockEmailChange: (email) => {
      dispatch(outOfStockSubscriptionEmailChanged(email))
    },
    onViewDetailsClicked: (route) => {
      dispatch(productPreviewViewDetailsClickedAsync(route))
    },
  }
}

const ProductPreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductPreview)

export default ProductPreviewContainer
