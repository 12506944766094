import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { List, Map } from "immutable"
import classNames from "classnames"
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup"
import PromoField from "highline/components/cart/promo_field"
import LineItemWithPrompt from "highline/components/cart/line_item_with_prompt"
import LoadingButton from "highline/components/loading_button"
import LineItem from "highline/components/cart/line_item"
import Spinner from "highline/components/spinner"
import SuggestedItemTile from "highline/components/cart/suggested_item_tile"
import LoadingCurtain from "highline/components/loading_curtain"
import LineItemRecord from "highline/records/line_item"
import NotificationList from "highline/components/cart/notification_list"
import PaypalButton from "highline/components/paypal_button"
import Gift from "highline/components/cart/gift"
import { LINE_ITEM_MESSAGE } from "highline/utils/preorder"
import AffirmEstimate from "highline/components/affirm/affirm_estimate"
import { containsGiftCard } from "highline/utils/cart_helper"
import initAffirm, { getNumericPrice } from "highline/utils/affirm"
import getConfig from "highline/config/application"
import Cta from "highline/components/cta"
import Link from "highline/components/secure_link"
import { getClientSideLink } from "highline/utils/link"
import styles from "highline/styles/components/cart/cart_contents.module.css"
import Script from "next/script"

const TRANSITION_TIMEOUT = 300
const { affirmPaymentEnabled } = getConfig()

const nonZeroAmount = (value) => (
  Boolean(value && value > 0)
)

const pantsLink = getClientSideLink("/shop/clothing/pants")
const shirtsLink = getClientSideLink("/shop/clothing/shirts")
const newArrivalsLink = getClientSideLink("/shop/new-arrivals")
const suitsLink = getClientSideLink("/shop/suits-and-blazers")


class CartContents extends React.PureComponent {
  static propTypes = {
    adyenMerchantSignature: PropTypes.string,
    bundleDiscountTotal: PropTypes.string,
    bundleDiscountTotalNumeric: PropTypes.number,
    cartLayout: PropTypes.oneOf(["cart_standard_width", "cart_double_width"]),
    enableGifting: PropTypes.bool,
    estimatedStoreCredit: PropTypes.string,
    estimatedStoreCreditNumeric: PropTypes.number,
    estimatedTotal: PropTypes.string,
    giftNote: PropTypes.string,
    internationalSurcharge: PropTypes.string,
    isGift: PropTypes.bool,
    isLoading: PropTypes.bool,
    isNavigatingToCheckout: PropTypes.bool,
    isPromoFieldOpen: PropTypes.bool,
    lineItems: ImmutablePropTypes.listOf(
      PropTypes.instanceOf(LineItemRecord),
    ),
    markdown:  ImmutablePropTypes.mapContains({
      display: PropTypes.string,
      numeric: PropTypes.string,
    }),
    newLineItemLoading: PropTypes.bool,
    notifications: ImmutablePropTypes.list,
    onCartGiftNoteChanged: PropTypes.func,
    onCartIsGiftInputToggle: PropTypes.func,
    onLineItemDecrement: PropTypes.func,
    onLineItemDelete: PropTypes.func,
    onLineItemIncrement: PropTypes.func,
    onLineItemWishlist: PropTypes.func,
    onMessageClick: PropTypes.func,
    onNotificationDismissal: PropTypes.func,
    onPaypalButtonClick: PropTypes.func,
    onPromoCodeInputChange: PropTypes.func,
    onPromoCodeAddSubmit: PropTypes.func,
    onPromoCodeRemoveSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuggestedItemClick: PropTypes.func,
    onSuggestedItemDismiss: PropTypes.func,
    onTogglePromoField: PropTypes.func,
    paypalInputs: ImmutablePropTypes.map,
    promo: ImmutablePropTypes.mapContains({
      code: PropTypes.string,
      total: PropTypes.string,
    }),
    promoCodeDetails: ImmutablePropTypes.mapContains({
      code: PropTypes.string,
      error: ImmutablePropTypes.map,
      isLoading: PropTypes.bool,
      isPromoCodeApplied: PropTypes.bool,
    }),
    promoError: PropTypes.string,
    shipping: ImmutablePropTypes.mapContains({
      name: PropTypes.string,
      total: PropTypes.string,
    }),
    showApplePayButton: PropTypes.bool,
    showLineItemPrompt: PropTypes.bool,
    showPaypalButton: PropTypes.bool,
    srToken: PropTypes.string,
    subtotal: PropTypes.string,
    suggestedItem: ImmutablePropTypes.map,
    tax: PropTypes.string,
    total: PropTypes.string,
  }

  static defaultProps = {
    cartLayout: "cart_double_width",
    enableGifting: false,
    giftNote: null,
    isGift: false,
    isNavigatingToCheckout: false,
    isPromoFieldOpen: false,
    lineItems: List(),
    markdown: Map(),
    onCartGiftNoteChanged: () => {},
    onCartIsGiftInputToggle: () => {},
    onMessageClick: () => {},
    onPaypalButtonClick: () => {},
    onPromoCodeAddSubmit: () => {},
    onPromoCodeInputChange: () => {},
    onPromoCodeRemoveSubmit: () => {},
    onPromoCodeSubmit: () => {},
    onTogglePromoField: () => {},
    promoCodeDetails: Map(),
    shipping: Map(),
    showApplePayButton: false,
    showLineItemPrompt: false,
    showPaypalButton: false,
    suggestedItem: Map(),
  }

  // Move this into a component called CartContents
  // This component then becomes the main wrapper (like previous cart container
  render() {
    const {
      adyenMerchantSignature,
      bundleDiscountTotal,
      bundleDiscountTotalNumeric,
      enableGifting,
      estimatedStoreCredit,
      estimatedStoreCreditNumeric,
      estimatedTotal,
      giftNote,
      cartLayout,
      isGift,
      isLoading,
      isNavigatingToCheckout,
      isPromoFieldOpen,
      lineItems,
      markdown,
      newLineItemLoading,
      notifications,
      onCartGiftNoteChanged,
      onCartIsGiftInputToggle,
      onSuggestedItemClick,
      onLineItemDelete,
      onLineItemDecrement,
      onLineItemIncrement,
      onLineItemWishlist,
      onNotificationDismissal,
      onMessageClick,
      onPaypalButtonClick,
      onPromoCodeAddSubmit,
      onPromoCodeInputChange,
      onPromoCodeRemoveSubmit,
      onSubmit,
      onSuggestedItemDismiss,
      onTogglePromoField,
      paypalInputs,
      promo,
      promoCodeDetails,
      promoError,
      showLineItemPrompt,
      showApplePayButton,
      showPaypalButton,
      subtotal,
      suggestedItem,
    } = this.props

    const getLineItemProps = (lineItem) => {
      return {
        bundleDiscount: lineItem.get("bundleDiscount"),
        bundles: lineItem.get("discountedBundles"),
        description: lineItem.get("description"),
        description2: lineItem.get("description2"),
        disableQuantityAdjustment: !lineItem.get("canAdjustQuantity"),
        discountedTotal: lineItem.getIn(["amount", "discounted"]),
        finalSale: lineItem.get("finalSale"),
        inStock: lineItem.get("inStock"),
        limitedQuantity: lineItem.get("limitedQuantity"),
        lineItemId: lineItem.get("id"),
        link: lineItem.get("path"),
        message: lineItem.get("isPreorder") ? LINE_ITEM_MESSAGE : "",
        name: lineItem.get("name"),
        price: lineItem.getIn(["amount", "full"]),
        quantity: lineItem.get("quantity"),
        sku: lineItem.get("sku"),
        thumbnailSrc: lineItem.get("image"),
        unitPrice: lineItem.get("discountedTotal"),
      }
    }

    return (
      <div
        className={ classNames(
          "component",
          "cart-component",
          styles.component,
          styles[cartLayout],
        ) }
      >
        <LoadingCurtain delay={ 400 } layout="medium" show={ isLoading } />
        { affirmPaymentEnabled && 
          <Script 
            strategy="lazyOnload">
            { initAffirm() }
          </Script> }
        <div className={ classNames(styles.lineItems, styles[cartLayout]) }>
          <NotificationList
            notifications={ notifications }
            onDismissal={ onNotificationDismissal }
            onMessageClick={ onMessageClick }
          />

          <ReactCSSTransitionGroup
            transitionName={ {
              enter: styles.emptyEnter,
              enterActive: styles.emptyEnterActive,
            } }
            transitionEnterTimeout={ TRANSITION_TIMEOUT }
            transitionLeave={ false }
          >
            { lineItems.isEmpty() &&
            <div key="empty" className={ styles.empty }>
              <div className={ styles.emptyText }>Your Cart is Empty</div>
              <div className={ styles.emptyDescriptionText }>It would look nicer with some new clothes in it.</div>
              <Link href={ pantsLink.get("href") } as={ pantsLink.get("as") }>
                <Cta align="inline" layout="primary-outline" href={ pantsLink.get("as") } className={ styles.emptyCta }>
                  Shop Pants
                </Cta>
              </Link>
              <Link href={ shirtsLink.get("href") } as={ shirtsLink.get("as") }>
                <Cta align="inline" layout="primary-outline" href={ shirtsLink.get("as") } className={ styles.emptyCta }>
                  Shop shirts
                </Cta>
              </Link>
              <Link href={ newArrivalsLink.get("href") } as={ newArrivalsLink.get("as") }>
                <Cta align="inline" layout="primary-outline" href={ newArrivalsLink.get("as") } className={ styles.emptyCta }>
                  Shop New Arrivals
                </Cta>
              </Link>
              <Link href={ suitsLink.get("href") } as={ suitsLink.get("as") }>
                <Cta align="inline" layout="primary-outline" href={ suitsLink.get("as") } className={ styles.emptyCta }>
                  Shop Suits & Blazers
                </Cta>
              </Link>
            </div>
            }
          </ReactCSSTransitionGroup>

          <ul className={ classNames(
            "cart-line-items",
            styles.lineItemsList,
          ) }
          >
            <ReactCSSTransitionGroup
              transitionName={ {
                leave: styles.lineItemLeave,
                leaveActive: styles.lineItemLeaveActive,
              } }
              transitionEnter={ false }
              transitionLeaveTimeout={ TRANSITION_TIMEOUT }
            >
              { !lineItems.isEmpty() &&
                <div className={ styles.card }>
                  <div className={ styles.cardTitle }>Your Items</div>
                  { lineItems.map((lineItem, index) => (
                    <li className={ styles.lineItem } key={ `${ lineItem.get("sku") }-${ index }` }>
                      { showLineItemPrompt &&
                      <LineItemWithPrompt
                        { ...getLineItemProps(lineItem) }
                        onDecrement={ onLineItemDecrement }
                        onIncrement={ onLineItemIncrement }
                        onDelete={ onLineItemDelete }
                        onWishlist={ onLineItemWishlist }
                      />
                      }
                      { !showLineItemPrompt &&
                      <LineItem
                        { ...getLineItemProps(lineItem) }
                        onRemove={ onLineItemDelete }
                        onDecrement={ onLineItemDecrement }
                        onIncrement={ onLineItemIncrement }
                      />
                      }
                    </li>
                  )) }
                </div>}
            </ReactCSSTransitionGroup>

            { newLineItemLoading &&
              <li className={ classNames(
                styles.lineItemLoading,
                lineItems.isEmpty() ? styles.lineItemLoadingEmpty : null,
              ) }>
                <Spinner className={ styles.spinner } />
              </li>
            }
          </ul>

          { !suggestedItem.isEmpty() && !lineItems.isEmpty() &&
            <SuggestedItemTile
              item={ suggestedItem }
              onClickPrice={ onSuggestedItemClick }
              onRemove={ onSuggestedItemDismiss }
            />
          }
        </div>

        { !lineItems.isEmpty() &&
          <footer className={ classNames(styles.footer, styles[cartLayout]) }>
            <div className={ styles.card }>
              <div className={ styles.cardTitle }>Order Details</div>
              <div className={ styles.footerItem }>

                { enableGifting &&
                <Gift
                  giftNote={ giftNote }
                  isGift={ isGift }
                  onCartGiftNoteChanged={ onCartGiftNoteChanged }
                  onCartIsGiftInputToggle={ onCartIsGiftInputToggle }
                />
                }

                <PromoField
                  className={ classNames(
                    styles.promoCodeForm,
                    styles.footerItem,
                  ) }
                  error={ promoError }
                  isPromoCodeApplied={ promoCodeDetails.get("isPromoCodeApplied") }
                  isPromoFieldOpen={ isPromoFieldOpen }
                  isLoading={ promoCodeDetails.get("isLoading") }
                  code={ promoCodeDetails.get("code") }
                  onAddPromoSubmit={ onPromoCodeAddSubmit }
                  onTogglePromoField={ onTogglePromoField }
                  onRemovePromoSubmit={ onPromoCodeRemoveSubmit }
                  onInputChange={ onPromoCodeInputChange }
                  placeholder={ "Apply a promo code" }
                />

                <dl className={ styles.subtotal }>
                  <dt>Subtotal</dt>
                  <dd>{ subtotal }</dd>
                </dl>

                { nonZeroAmount(markdown.get("numeric")) &&
                <dl className={ styles.markdown }>
                  <dt>Markdown Discount</dt>
                  <dd>{ markdown.get("display") }</dd>
                </dl>
                }

                { nonZeroAmount(bundleDiscountTotalNumeric) &&
                <dl className={ styles.bundleDiscount }>
                  <dt>Bundle Discount</dt>
                  <dd>{ bundleDiscountTotal }</dd>
                </dl>
                }

                { promo && !promo.isEmpty() &&
                <dl className={ styles.appliedPromo }>
                  <dt>Promo</dt>
                  <dd>{ promo.get("total") }</dd>
                </dl>
                }

                { estimatedStoreCredit && nonZeroAmount(estimatedStoreCreditNumeric) &&
                <dl className={ styles.storeCredit }>
                  <dt>Store Credit</dt>
                  <dd>{ estimatedStoreCredit }</dd>
                </dl>
                }
              </div>
              <dl className={ classNames(
                styles.footerItem,
                styles.total,
              ) }>
                <dt>Total <span className={ styles.shippingMessage }>— Free U.S. Shipping & Returns</span></dt>
                <dd>{ estimatedTotal }</dd>
              </dl>

              { !containsGiftCard(lineItems) && affirmPaymentEnabled &&
                <div className={ classNames( styles.affirm) }>
                  <AffirmEstimate source="cart" totalAmount={ getNumericPrice(estimatedTotal) } />
                </div>
              }

              <form
                className={ styles.checkoutForm }
                method="post"
                onSubmit={ (e) => {
                  e.preventDefault()
                  onSubmit()
                } }
              >
                <LoadingButton
                  aria-label="Proceed to Checkout Flow"
                  className="checkout-button"
                  align="stretch"
                  loading={ isNavigatingToCheckout }
                  rounded
                >
                Checkout
                </LoadingButton>
              </form>
              {
                (showPaypalButton || showApplePayButton) &&
              <div
                className={ classNames(
                  styles.guestCheckoutOptions,
                ) }
              >
                <span>or</span>
                <h4>Want to checkout quicker?</h4>
              </div>
              }
              { showPaypalButton &&
              <div
                className={ styles.checkoutForm }
              >
                <PaypalButton
                  adyenMerchantSignature={ adyenMerchantSignature }
                  onPaypalButtonClick={ onPaypalButtonClick }
                  paypalInputs={ paypalInputs }
                />
              </div>
              }
            </div>
          </footer>
        }
      </div>
    )
  }
}

export default CartContents
