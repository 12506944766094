import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import AlertTag from "highline/components/alert_tag"

const OnlyXLeft = ({ className, layout, quantity }) => (
  <AlertTag
    className={ classNames(
      "only-x-left-component",
      className,
    ) }
    layout={ layout }
  >
    Only { quantity } Left
  </AlertTag>
)

OnlyXLeft.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOf(["medium", "small"]),
  quantity: PropTypes.number.isRequired,
}

OnlyXLeft.defaultProps = {
  layout: "medium",
}

export default OnlyXLeft
