import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS } from "immutable"
import classNames from "classnames"
import AlertTag from "highline/components/alert_tag"
import Delay from "highline/components/delay"
import Imgix from "highline/components/imgix"
import OnlyXLeft from "highline/components/only_x_left"
import Spinner from "highline/components/spinner"
import { CloseIcon, MinusIcon, PlusIcon } from "highline/components/icons"

import styles from "highline/styles/components/cart/line_item.module.css"

class LineItem extends React.PureComponent {
  static propTypes = {
    bundles: ImmutablePropTypes.list,
    children: PropTypes.node,
    className: PropTypes.string,
    description: PropTypes.string.isRequired,
    description2: PropTypes.string,
    disableQuantityAdjustment: PropTypes.bool,
    discountedTotal: PropTypes.string,
    finalSale: PropTypes.bool,
    inStock: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isRemoveHidden: PropTypes.bool,
    limitedQuantity: PropTypes.number,
    lineItemId: PropTypes.number,
    link: PropTypes.string.isRequired,
    message: PropTypes.string,
    name: PropTypes.string.isRequired,
    onDecrement: PropTypes.func,
    onIncrement: PropTypes.func,
    onRemove: PropTypes.func,
    price: PropTypes.string,
    quantity: PropTypes.number,
    showPrompt: PropTypes.bool,
    sku: PropTypes.string,
    thumbnailSrc: PropTypes.string,
    unitPrice: PropTypes.string,
  }

  static defaultProps = {
    disableQuantityAdjustment: false,
    isRemoveHidden: false,
    message: "",
    onDecrement: () => {},
    onIncrement: () => {},
    onRemove: () => {},
  }

  state = {
    loading: false,
  }

  loadingComplete = () => {
    this.setState({ loading: false })
  }

  increment = () => {
    this.setState({ loading: true })

    const lineItems = fromJS([{
      isBundle: false,
      location: "cart",
      name: this.props.name,
      price: this.props.price,
      discountedTotal: this.props.discountedTotal,
      quantity: 1,
      sku: this.props.sku,
    }])

    this.props.onIncrement(lineItems, this.loadingComplete)
  }

  decrement = () => {
    this.setState({ loading: true })

    const lineItems = fromJS([{
      price: this.props.price,
      discountedTotal: this.props.discountedTotal,
      quantity: 1,
      sku: this.props.sku,
    }])

    this.props.onDecrement(lineItems, this.loadingComplete)
  }

  renderQuantitySubcomponent = () => {
    const { disableQuantityAdjustment, quantity } = this.props
    const { loading } = this.state

    return (
      <Fragment>
        <div className={ styles.quantity }>
          { !disableQuantityAdjustment &&
            <button
              aria-label="Decrease the item quantity by one"
              className={ classNames(
                styles.quantityButton,
                styles.decrement,
              ) }
              onClick={ this.decrement }
              disabled={ loading }
            ><MinusIcon /></button>
          }

          <span className={ styles.qtyWrapper }>
            { loading
              ? <Delay delay={ 200 }><Spinner className={ styles.spinner } /></Delay>
              : <span className={ styles.qty }>{ disableQuantityAdjustment && "Qty " }{quantity}</span>
            }
          </span>

          { !disableQuantityAdjustment &&
            <button
              aria-label="Increase the item quantity by one"
              className={ classNames(
                styles.quantityButton,
                styles.increment,
              ) }
              onClick={ this.increment }
              disabled={ loading }
            ><PlusIcon /></button>
          }
        </div>
      </Fragment>
    )
  }

  renderLineItemBundles = (bundles) => {
    if (!bundles || bundles.size < 1) { return null }
    const bundleNames = Array.from(new Set(bundles.map((b) => b.get("bundleName"))))
    return <Fragment>
      { bundleNames.map((name, index) => (
        <div key={ `bundle-${index}` } className={ classNames([styles.description, "lineItemBundle"]) }>
          { `Bundle: ${name}` }
        </div>
      ))}
    </Fragment>
  }

  render() {
    const {
      bundles,
      children,
      className,
      description,
      description2,
      discountedTotal,
      finalSale,
      link,
      name,
      onRemove,
      message,
      price,
      quantity,
      isLoggedIn,
      showPrompt,
      sku,
      thumbnailSrc,
      isRemoveHidden,
      inStock,
      limitedQuantity,
      unitPrice,
    } = this.props

    const hasDiscount = discountedTotal !== price

    return (
      <div
        className={ classNames(
          "component",
          "line-item-component",
          styles.component,
          hasDiscount && styles.hasBundle,
          className,
        ) }
      >
        <div className={ styles.itemContainer }>
          <div>
            <a href={ link } tabIndex={ showPrompt ? -1 : 0 } >
              <Imgix
                className={ styles.thumbnail }
                htmlAttributes={ {
                  alt: `${name} thumbnail`,
                  "aria-label": `${name} thumbnail`,
                } }
                src={ thumbnailSrc }
                width={ 94 } // Set by styles.thumbnail
              />
            </a>
          </div>
          { !showPrompt &&
            <div className={ styles.details }>
              { !isRemoveHidden &&
                  <button
                    aria-label={ isLoggedIn ? `Open Additional Cart Options for ${name}` : `Remove ${name} from cart` }
                    className={ classNames("close-button", styles.closeButton) }
                    onClick={ () => onRemove(sku, quantity) }
                  >
                    <CloseIcon />
                  </button>
              }
              <div className={ styles.top }>

                <a href={ link } className={ styles.name }>{ name }</a>
                <div className={ styles.description }>{ description }</div>
                { description2 &&
                    <div className={ styles.description2 }>{ description2 }</div>
                }
                { unitPrice && quantity > 1 &&
                  <div className={ classNames(styles.description) }>{ `Price: ${unitPrice}` }</div>
                }
                { this.renderLineItemBundles(bundles) }
                { inStock && limitedQuantity > 0 && limitedQuantity <= 5 &&
                    <OnlyXLeft
                      className={ styles.onlyXLeft }
                      layout="small"
                      quantity={ limitedQuantity }
                    />
                }
                { finalSale &&
                <div className={ styles.finalSale }>Item is final sale. No returns or exchanges.</div>
                }
                { !inStock &&
                  <AlertTag
                    className={ styles.soldOut }
                    layout="small"
                  >
                      Sold Out
                  </AlertTag>
                }
              </div>
            </div>
          }
        </div>
        <div className={ styles.bottom }>
          <div className={ styles.quanityStockContainer }>
            { quantity && this.renderQuantitySubcomponent() }
          </div>
          { price &&
            <div className={ styles.priceContainer }>
              <div className={ classNames(styles.price, hasDiscount && styles.strikethrough) }>{ price }</div>
              { hasDiscount &&
                <div className={ styles.discountedTotal }>{ discountedTotal }</div>
              }
            </div>
          }
        </div>
        { children }
        { message &&
          <div className={ styles.message }>{ message }</div>
        }
      </div>
    )
  }
}

export default LineItem
