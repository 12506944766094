import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { List } from "immutable"
import classNames from "classnames"
import Swiper from "react-id-swiper/lib/custom"
import Imgix from "highline/components/imgix"
import styles from "highline/styles/components/pdp/pdp_drawer_images.module.css"

const PDP_IMAGE_WIDTH = 285 // 285px is max width from product_preview.css
const PDP_SINGLE_IMAGE_WIDTH = 375 // 375px is max width from thumbnail_gallery.css

const PdpDrawerImages = ({ images, showImages }) => (
  <div
    className={ classNames(
      "component",
      "pdp-drawer-images-component",
      styles.component,
    ) }
  >
    {showImages &&
      <Swiper
        slidesPerView="auto"
        grabCursor
        freeMode
        rebuildOnUpdate
        spaceBetween={ 0 }
      >
        { images.map((imageData) => (
          <div
            className={ images.size > 1 ? styles.slideWrapper : styles.slideWrapperSingleImage }
            key={ imageData.get("url") }>
            <Imgix
              className={ styles.image }
              htmlAttributes={ {
                alt: imageData.get("caption"),
                "aria-label": imageData.get("caption"),
              } }
              src={ imageData.get("url") }
              width={ images.size > 1 ? PDP_IMAGE_WIDTH : PDP_SINGLE_IMAGE_WIDTH }
            />
          </div>
        )) }
      </Swiper>
    }
  </div>
)

PdpDrawerImages.propTypes = {
  images: ImmutablePropTypes.list,
  showImages: PropTypes.bool,
}

PdpDrawerImages.defaultProps = {
  images: List(),
  showImages: true,
}

export default PdpDrawerImages
